$(document).ready(function () {
    $(".scroller").on("click", function(event) {
        event.preventDefault();
        var id = $(this).attr("href"),
            top = $(id).offset().top - 36;
        $("body, html").animate({scrollTop: top}, 400);
    });

    $(".choice-cards-slider, .reviews-slider, .answers-slider").slick({
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    dots: false,
                    infinite: true,
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    adaptiveHeight: true
                }
            }
        ]
    });

    $(".fancybox").fancybox({
        baseClass: "popup-policy",
        infobar: false,
        buttons: false,
        thumbs: false,
        margin: 0,
        touch: {
            vertical: false
        },
        animationEffect: false,
        transitionEffect: "slide",
        transitionDuration: 500
    });

    /* Forms */
    $("form").submit(function(){
        return false;
    });

    $("[name=VISITOR_PHONE]").mask("+7 (999) 999-99-99");
    $("[name=VISITOR_DATE]").mask("99.99.9999");

    jQuery.validator.addMethod("laxEmail", function(value, element) {
        return this.optional( element ) || /^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/.test( value );
    }, "Введите корректный email.");

    $(".callback-form").validate({
        rules: {
            VISITOR_NAME: {
                required: true
            },
            VISITOR_PHONE: {
                required: true
            }
        },
        messages: {},
        errorPlacement: function(error, element) {},
        submitHandler: function(form) {
            var json = {
                VISITOR_FORM: "callback",
                VISITOR_NAME:  $(form).find("input[name='VISITOR_NAME']").val(),
                VISITOR_PHONE: $(form).find("input[name='VISITOR_PHONE']").val()
            };

            sendForm(json);
        }
    });

    $(".choice-cards__item-btn").click(function (e) {
        var $this       = $(this),
            card        = "",
            cardName    = $this.siblings(".choice-cards__item-name").text(),
            cardPeriod  = $this.siblings(".choice-cards__item-period").text(),
            cardCountry = $this.siblings(".choice-cards__item-country").text(),
            cardPrice   = $this.siblings(".choice-cards__item-price").text();

        card = cardName + " " + cardPeriod + " " + cardCountry + " " + cardPrice;

        $(".card-form [name='VISITOR_CARDNAME']").val(card);
    });

    $(".m-choice-cards__select-header").click(function () {
        var $this = $(this).closest(".m-choice-cards__select");

        if($this.hasClass("m-choice-cards__select_opened")) {
            $this.removeClass("m-choice-cards__select_opened");
        } else {
            $this.addClass("m-choice-cards__select_opened");
        }
    });

    $(".m-choice-cards__select-item").click(function () {
        var $this = $(this),
            thisHtml  = $this.html(),
            thisValue = $this.data("value"),
            thisUrl   = $this.data("url");

        $(".m-choice-cards__select-selected")
            .data("value", thisValue)
            .attr("data-value", thisValue)
            .data("url", thisUrl || "")
            .attr("data-url", thisUrl || "")
            .html(thisHtml);

        $(".m-choice-cards__select").removeClass("m-choice-cards__select_opened");
    });

    $(".m-choice-cards__btn").click(function () {
        var $this = $(this),
            cardValue = $(".m-choice-cards__select-selected").data("value"),
            cardUrl   = $(".m-choice-cards__select-selected").data("url");

        if(!cardUrl.length) {
            $(".card-form [name='VISITOR_CARDNAME']").val(cardValue);

            $.fancybox.open($("#popup-card"), {
                infobar: false,
                buttons: false,
                thumbs: false,
                margin: 0,
                touch: {
                    vertical: false
                },
                animationEffect: false,
                transitionEffect: "slide",
                transitionDuration: 500
            });
        } else {
            window.open(cardUrl, "_blank");
        }
    });

    $(".card-form").validate({
        rules: {
            VISITOR_NAME: {
                required: true
            },
            VISITOR_ADDRESS: {
                required: true
            },
            VISITOR_NUMBER: {
                required: true
            },
            VISITOR_MARK: {
                required: true
            },
            VISITOR_DATE: {
                required: true
            },
            VISITOR_SHIPPING: {
                required: true
            },
            VISITOR_PHONE: {
                required: true
            }
        },
        messages: {},
        errorPlacement: function(error, element) {},
        submitHandler: function(form) {
            var json = {
                VISITOR_FORM:     "card",
                VISITOR_NAME:     $(form).find("input[name='VISITOR_NAME']").val(),
                VISITOR_ADDRESS:  $(form).find("input[name='VISITOR_ADDRESS']").val(),
                VISITOR_NUMBER:   $(form).find("input[name='VISITOR_NAME']").val(),
                VISITOR_MARK:     $(form).find("input[name='VISITOR_MARK']").val(),
                VISITOR_DATE:     $(form).find("input[name='VISITOR_DATE']").val(),
                VISITOR_SHIPPING: $(form).find("input[name='VISITOR_SHIPPING']").val(),
                VISITOR_PHONE:    $(form).find("input[name='VISITOR_PHONE']").val(),
                VISITOR_CARDNAME: $(form).find("input[name='VISITOR_CARDNAME']").val()
            };

            sendForm(json);
        }
    });

    function sendForm(json) {
        $.ajax({
            type: "POST",
            url: "/send.php",
            data: "json=" + JSON.stringify(json),
            success: function(data, type) {
                $.fancybox.close();
                $.fancybox.open($("#popup-success"), {
                    infobar: false,
                    buttons: false,
                    thumbs: false,
                    margin: 0,
                    touch: {
                        vertical: false
                    },
                    animationEffect: false,
                    transitionEffect: "slide",
                    transitionDuration: 500
                });
            }
        });
    }
});